import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { HttpClientModule } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor, ErrorInterceptor } from "app/auth/helpers";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { DndModule } from "ng2-dnd";

const appRoutes: Routes = [
	{
		path: "pages",
		loadChildren: () => import("./main/pages/pages.module").then((m) => m.PagesModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/order/order.module").then((m) => m.OrderModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/comments/comments.module").then((m) => m.CommentsModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/order-history/order-history.module").then((m) => m.OrderHistoryModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/admin/admin.module").then((m) => m.AdminModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/feederportal/feederportal.module").then((m) => m.FeederportalModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/cod-collection/cod-collection.module").then((m) => m.CodCollectionModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/courier/courier.module").then((m) => m.CourierModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/customer-address/customer-address.module").then((m) => m.CustomerAddressModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/edit-order-completed/edit-order-completed.module").then((m) => m.EditOrderCompletedModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/merchant-address/merchant-address.module").then((m) => m.MerchantAddressModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/rider-details/rider-details.module").then((m) => m.RiderDetailsModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/sama-point/sama-point.module").then((m) => m.SamaPointModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/vehicle/vehicle.module").then((m) => m.VehicleModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/vehicle-brand-model/vehicle-brand-model.module").then((m) => m.VehicleBrandModelModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/vehicle-type/vehicle-type.module").then((m) => m.VehicleTypeModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/dashboard/dashboard.module").then((m) => m.DashboardModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/assign-rider/assign-rider.module").then((m) => m.AssignRiderModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/new-cod/new-cod.module").then((m) => m.NewCodModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/order-change-log/order-change-log.module").then((m) => m.OrderChangeLogModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/merchant-payout/merchant-payout.module").then((m) => m.MerchantPayoutModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/pickup-management/pickup-management.module").then((m) => m.PickupManagementModule),
	},
	{
		path: "app",
		loadChildren: () => import("./main/samayama/daily-cod-report/daily-cod-report.module").then((m) => m.DailyCODReportModule),
	},

	{
		path: "app",
		loadChildren: () => import("./main/samayama/eod-sorting/eod-sorting.module").then((m) => m.EODSortingModule),
	},

	{
		path: "app",
		loadChildren: () => import("./main/samayama/manage-payout/manage-payout.module").then((m) => m.ManagePayoutModule),
	},
	{
		path: "",
		redirectTo: "app/manage-order",
		pathMatch: "full",
	},
	{
		path: "**",
		redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
	},
];

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(appRoutes, {
			scrollPositionRestoration: "enabled", // Add options right here
			relativeLinkResolution: "legacy",
		}),
		TranslateModule.forRoot(),

		//NgBootstrap
		NgbModule,
		ToastrModule.forRoot(),
		DndModule.forRoot(),

		// Core modules
		CoreModule.forRoot(coreConfig),
		CoreCommonModule,
		CoreSidebarModule,
		CoreThemeCustomizerModule,

		// App modules
		LayoutModule,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		// { provide: NgbDateParserFormatter, useClass: DateParserFormatter }
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
