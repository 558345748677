import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
	{
		id: "order",
		title: "Manage Order",
		type: "item",
		icon: "plus-square",
		url: "app/manage-order",
		role: ["Admin"],
	},

	{
		id: "order-history",
		title: "Order History",
		type: "item",
		icon: "file-text",
		url: "app/order-history",
		role: ["Admin"],
	},
	{
		id: "pickup-management",
		title: "Manage FM",
		translate: "Manage FM",
		type: "item",
		icon: "grid",
		url: "app/manage-fm",
		role: ["Admin"],
	},
	{
		id: "merchant-comments",
		title: "Comments",
		type: "item",
		icon: "send",
		url: "app/merchant-comments",
		role: ["Admin"],
	},

	{
		id: "courier",
		title: "Courier",
		translate: "Rider",
		type: "item",
		icon: "gift",
		url: "app/courier",
		role: ["Admin"],
	},
	{
		id: "eod-sorting",
		title: "EOD Sorting",
		translate: "EOD Sorting",
		type: "item",
		icon: "shopping-bag",
		url: "app/eod-sorting",
		role: ["Admin"],
	},
	{
		id: "manage-payout",
		title: "Manage Payout",
		type: "item",
		icon: "credit-card",
		url: "app/manage-payout",
		role: ["Admin"],
	},
	{
		id: "others",
		title: "Others",
		type: "collapsible",
		icon: "life-buoy",
		children: [
			{
				id: "edit-order-completed",
				title: "Edit Completed Order",
				type: "item",
				icon: "edit",
				url: "app/edit-order-completed",
				role: ["Admin"],
			},
			{
				id: "sama-point",
				title: "Sama-Point",
				translate: "Sama Point",
				type: "item",
				icon: "map-pin",
				url: "app/sama-point",
				role: ["Admin"],
			},
			{
				id: "merchant",
				title: "Merchant",
				translate: "Merchant",
				type: "item",
				icon: "shopping-bag",
				url: "app/merchant-address",
				role: ["Admin"],
			},
			{
				id: "feederportal",
				title: "Feeder Portal",
				type: "item",
				icon: "truck",
				url: "app/feederportal",
				role: ["Admin", "Feeder"],
			},
			{
				id: "customer",
				title: "Customer",
				translate: "Customer",
				type: "item",
				icon: "users",
				url: "app/customer",
				role: ["Admin"],
			},
			{
				id: "vehicles",
				title: "Vehicles",
				type: "collapsible",
				icon: "life-buoy",
				children: [
					{
						id: "vehicle-type",
						title: "Type",
						type: "item",
						icon: "plus-square",
						url: "app/vehicle-type",
						role: ["Admin"],
					},
					{
						id: "vehicle-brand-model",
						title: "Brand & Model",
						type: "item",
						icon: "plus-square",
						url: "app/vehicle-brand-model",
						role: ["Admin"],
					},
					{
						id: "vehicle",
						title: "Vehicle",
						type: "item",
						icon: "plus-square",
						url: "app/vehicle",
						role: ["Admin"],
					},
				],
			},
			{
				id: "admin",
				title: "Admin",
				translate: "Admin",
				type: "item",
				icon: "user",
				url: "app/admin",
				role: ["Admin"],
			},
			{
				id: "dashboard",
				title: "Dashboard",
				translate: "Dashboard",
				type: "item",
				icon: "home",
				url: "app/dashboard",
				role: ["Admin"],
			},
			{
				id: "order-change-log",
				title: "Order Change Log",
				translate: "Order Change Log",
				type: "item",
				icon: "corner-up-left",
				url: "app/order-change-log",
				role: ["Admin"],
			},
			{
				id: "assign-rider",
				title: "Assign Rider",
				translate: "Assign Rider",
				type: "item",
				icon: "user",
				url: "app/assign_rider",
				role: ["Admin"],
			},

			{
				id: "cod-collection",
				title: "COD Log",
				type: "item",
				icon: "dollar-sign",
				url: "app/cod-collection",
				role: ["Admin"],
			},
			{
				id: "daily-cod-report",
				title: "Daily COD Report",
				type: "item",
				icon: "dollar-sign",
				url: "app/daily-cod-report",
				role: ["Admin"],
			},
		],
	},
];
