import { EnvironmentUrlService } from './environment-url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { retry,shareReplay, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) { }

  public getData = (route: string) => {
    return this.http.get(this.createCompleteRoute(route, this.envUrl.apiUrl)).pipe(retry(3),catchError(()=>{
      return EMPTY;
    }),
      shareReplay()
    );
  }
 
  public create = (route: string, body) => {
    return this.http.post(this.createCompleteRoute(route, this.envUrl.apiUrl), body, this.generateHeaders());
  }
 
  public postData = (route: string, body) => {
    return this.http.post(this.createCompleteRoute(route, this.envUrl.apiUrl), body, this.generateHeaders());
  }

  public putData = (route: string, body) => {
    return this.http.put(this.createCompleteRoute(route, this.envUrl.apiUrl), body, this.generateHeaders());
  }
 
  public delete = (route: string) => {
    return this.http.delete(this.createCompleteRoute(route, this.envUrl.apiUrl));
  }
 
  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }
 
  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
  }
}
