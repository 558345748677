import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  login(username: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/token/`, { username, password })
      .pipe(
        map(user => {
          if (user) {
            var info = JSON.parse(atob(user.data.accessToken.split('.')[1]));
            user.token = user.data.accessToken;
            user.id_admin = 1;
            user.sa_name = user.data.full_name;
            user.role = user.data.role;
            delete user.data.accessToken; delete user.refreshToken;
            localStorage.setItem('currentUser', JSON.stringify(user));
            setTimeout(() => {
              this._toastrService.success(
                '🎉🎉🎉',
                'Welcome oh glorious and mighty lord of Samayama',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
